import liDehnConfig from "../assets/json/nl/config.json";
import { mapState } from "vuex";
const INSTALL_ENV = process.env.VUE_APP_INSTALL_ENV;
export default {
  data: () => ({
    isMobileApp: false,
    debug: false,
    language: "nl",
    installEnv: INSTALL_ENV,
    entities:{
      "APP":{
        matrix_md:"dehn_matrix_md",
        matrix_sd:"dehn_matrix_sd",
        matrix_ce:"dehn_matrix_ce",
        projects:"dehn_projects",
        products:"dehn_product_items",
        info:"dehn_info_items",
      },
      "ACC":{
        matrix_md:"dehn_matrix_md_ACC",
        matrix_sd:"dehn_matrix_sd_ACC",
        matrix_ce:"dehn_matrix_ce_ACC",
        projects:"dehn_projects_ACC",
        products:"dehn_product_items",
        info:"dehn_info_items",
      },
      "MATRIX":{
        matrix_md:"dehn_matrix_md",
        matrix_sd:"dehn_matrix_sd",
        matrix_ce:"dehn_matrix_ce",
        projects:"dehn_projects",
        products:"dehn_product_items",
        info:"dehn_info_items",
        
      },
    }
  }),
  beforeDestroy() {
    if (typeof window === "undefined") return;
    window.removeEventListener("resize", this.onResize, { passive: true });
  },
  mounted() {
    this.onResize();
    window.addEventListener("resize", this.onResize, { passive: true });
    let project = localStorage.getItem("liProjectDehn");
    if (typeof project == "string") {
      project = JSON.parse(project);
    }
    if (typeof project == "object") {
      this.gotProject = true;
      // console.log(this.$route);
      // console.log('got project, want to view?', this.$route.name);
    }
  },
  computed: {
    ...mapState("liDehnProject", ["general", "project", "newProjectStore"]),
    appConfig() {
      return liDehnConfig;
    },
    products() {
      return this.$store.getters["liDehnProject/getCollection"](`products_${this.language}`);
    },
    info() {
      return this.$store.getters["liDehnProject/getCollection"](`info_${this.language}`);
    },
  },
  methods: {
    openInfoModal(_identifier) {
      this.$root.$children[0].$refs.liInfoModal.open(_identifier);
    },
    product(identifier) {
      if (this.products != undefined) {
        return this.products.find((item) => {
          return item.identifier == identifier;
        });
      } else {
        return null;
      }
    },
    productByArticleNo(article_number) {
      if (this.products != undefined) {
        return this.products.find((item) => {
          console.log(item, article_number, item.article_number, article_number == item.article_number);
          return item.article_number == article_number;
        });
      } else {
        return null;
      }
    },
    // RULES
    rule_showProductSD(selectedItem, project) {
      let _return = false;

      if (this.rule_NeedLocation(project)) {
        // console.log("need location!");
        if (this.rule_NeedLpZone(selectedItem, project)) {
          // console.log("needLPL ZONE", selectedItem);
          if (selectedItem.lplZone == "") {
            _return = false;
          } else {
            _return = true;
          }
        }
      }

      if (selectedItem.ampere == "" || selectedItem.shortCircuitResistance == "" || selectedItem.preAmp == "" || selectedItem.cableLength == "" || selectedItem.cableLength == "<10") {
        _return = false;
      } else {
        _return = true;
      }
      return _return;
    },
    rule_showProductCE(selectedItem, project) {
      let _return = false;

      if (this.rule_NeedLocation(project)) {
        // console.log("need location!");
        if (this.rule_NeedLpZone(selectedItem, project)) {
          // console.log("needLPL ZONE", selectedItem);
          if (selectedItem.lplZone == "") {
            _return = false;
          } else {
            _return = true;
          }
        }
      }

      if (selectedItem.ampere == "" || selectedItem.shortCircuitResistance == "" || selectedItem.preAmp == "" || selectedItem.cableLength == "" || selectedItem.cableLength == "<10") {
        _return = false;
      } else {
        _return = true;
      }

      console.log("rule_showProductCE", _return);
      return _return;
    },
    rule_NeedLpZone(selectedItem, project) {
      // console.log(project.external);
      return project.external == 1 && selectedItem.external == 1 && selectedItem.cableLength == ">10" ? true : false;
    },
    rule_NeedLocation(project) {
      return project.external == 1 ? true : false;
    },
    rule_ProductNotRequired(selectedItem) {
      return selectedItem.cableLength == "<10" ? true : false;
    },
    rule_NeedPhaseSd(selectedItem) {
      return Number(selectedItem.ampere) <= 63 ? true : false;
    },
    rule_SetPhase(selectedItem) {
      return selectedItem.ampere > 63 ? true : false;
    },
    rule_SetPhase_filters(selectedItem) {
      return selectedItem.aw != null && selectedItem.aw.slice(3) > 40 ? true : false;
    },
    // SEARCH PRODUCTS
    searchProduct(item, queryText) {
      return item.name.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1 || item.article_number.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1;
    },
    compileLPLClass(product) {
      let comp = [];
      if (product.lpl_class_I == 1) {
        comp.push("I");
      }
      if (product.lpl_class_II == 1) {
        comp.push("II");
      }
      if (product.lpl_class_III == 1) {
        comp.push("III");
      }
      if (product.lpl_class_IV == 1) {
        comp.push("IV");
      }
      return comp.join("-");
    },
    fetchProducts() {
      this.$store.dispatch("liDehnProject/GET_COLLECTION", {
        request: {
          object: this.entities[this.installEnv].products,
          where: "status [=>] 1",
          orderBy: "sequence ASC",
          select: "name, identifier, description, thumbnailpath, status, article_number, grid, multiple_grid, ampere, type, url, multiple_url, combinedProduct, lpl_class_I, lpl_class_II, lpl_class_III, lpl_class_IV, preAmp, phase, colour",
        },
        collectionName: `products_${this.language}`,
      });
    },
    fetchInfo() {
      this.$store.dispatch("liDehnProject/GET_COLLECTION", {
        request: {
          object: this.entities[this.installEnv].info,
          where: "status [=>] 1",
          orderBy: "sequence ASC",
          select: "identifier,name,sequence,parent,status,image,text,title,language,unique_identifier,image_mobile",
        },
        collectionName: `info_${this.language}`,
      });
    },
    stripTags(value) {
      if (!value) return "";
      value = value.toString();
      // value - eturn value.replace("<br>", " ");
      return value.replace(/(<([^>]+)>)/gi, " ");
    },
    openUrl(url) {
      window.open(url, "_blank");
    },
    triggerHome() {
      console.log("triggerHome");
      if (this.$route.name != "Home") {
        this.$router.push("/");
      }
    },
    onResize() {
      this.isMobileApp = window.innerWidth < 600;
    },
    addCustomToStore(toValue) {
      const VALUE_LOCATOR = toValue.indexOf("=");
      const VALUE_TO_SET = toValue.substring(0, VALUE_LOCATOR);
      const ACTUAL_VALUE = toValue.substring(VALUE_LOCATOR + 1, toValue.length);
      this.$store.dispatch("liDehnProject/SET_CUSTOM_VALUE", {
        path: VALUE_TO_SET,
        value: ACTUAL_VALUE,
      });
    },
    /**
     * Returns a GUID 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'
     */
    generateUUID: function () {
      // Public Domain/MIT
      var d = new Date().getTime();
      if (typeof performance !== "undefined" && typeof performance.now === "function") {
        d += performance.now(); //use high-precision timer if available
      }
      var uuid = "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
        var r = (d + Math.random() * 16) % 16 | 0;
        d = Math.floor(d / 16);
        return (c === "x" ? r : (r & 0x3) | 0x8).toString(16);
      });
      return uuid;
    },
    // getCustomFromStore(path){
    // console.log(this.$store.getters["liDehnProject/getQuestions"](path));
    // }
  },
};
