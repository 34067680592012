<template>
  <v-container fluid fill-height class="bg-dark">
    <v-layout align-center justify-center>
      <v-flex xs12 sm10 md8 lg6 style="padding-top: 100px">
        <v-overlay absolute :value="submitting" />
        <v-card elevation="10" class="mb-10">
          <v-toolbar elevation="0" class="bg-light">
            <v-tabs v-model="authTabs" show-arrows>
              <v-tab :value="0" class="font-weight-black text-none border-right" :class="authTabs != 0 ? 'bg-light text-dark' : ''">
                {{ appConfig.texts.login.title }}
              </v-tab>
              <v-tab :value="1" class="font-weight-black text-none border-right" :class="authTabs != 1 ? 'bg-light text-dark' : ''">
                {{ appConfig.texts.login.accountRequestTitle }}
              </v-tab>
              <v-tab :value="1" class="font-weight-black text-none border-right" :class="authTabs != 2 ? 'bg-light text-dark' : ''">
                {{ appConfig.texts.login.passwordResetTitle }}
              </v-tab>
            </v-tabs>
          </v-toolbar>
          <v-divider />
          <v-tabs-items v-model="authTabs">
            <!-- LOGIN -->
            <v-tab-item>
              <v-card-text class="pt-10">
                <v-form ref="loginForm">
                  <v-text-field :label="appConfig.texts.login.loginLabel" v-model="user.email" filled prepend-icon="mdi-account" autocomplete="emailaddress" type="email" required></v-text-field>
                  <v-text-field :label="appConfig.texts.login.passwordLabel" v-model="user.password" filled :type="passwordType ? 'text' : 'password'" prepend-icon="mdi-lock" autocomplete="current-password" :append-icon="passwordType ? 'mdi-eye' : 'mdi-eye-off'" @click:append="passwordType = !passwordType" required></v-text-field>
                </v-form>
              </v-card-text>
              <v-card-actions>
                <v-btn class="mx-auto px-15 mb-4" color="primary" @click.prevent="doLogin()" :loading="submitting">
                  {{ appConfig.texts.login.buttonLabel }}
                </v-btn>
                <v-snackbar color="red darken-1" outlined pill v-model="error" absolute style="bottom: 5px">
                  {{ errorText }}
                  <template v-slot:action="{ attrs }">
                    <v-btn color="primary" small v-bind="attrs" @click="closeError">
                      {{ appConfig.texts.login.closeErrorButtonLabel }}
                    </v-btn>
                  </template>
                </v-snackbar>
              </v-card-actions>
            </v-tab-item>
            <!-- AANVRAGEN -->
            <v-tab-item>
              <template v-if="!requestAccountDone">
                <v-card-text class="pa-0">
                  <div class="pa-3 bg-white" v-html="appConfig.texts.login.accountRequestDescription" />
                  <v-form class="pa-3 bg-light" ref="formRequest" v-model="validRequest" lazy-validation>
                    <div class="px-2 py-3 bg-white">
                      <label>
                        <b> {{ appConfig.texts.login.request.headers.company }} </b>
                      </label>
                      <v-divider class="mb-3" />
                      <v-text-field v-model="requestAccount.company" :label="appConfig.texts.login.request.company" required :rules="requiredRules" />
                      <div class="d-flex">
                        <v-text-field v-model="requestAccount.street" :label="appConfig.texts.login.request.street" required class="w-75 pr-3" :rules="requiredRules" />
                        <v-text-field v-model="requestAccount.number" :label="appConfig.texts.login.request.number" required class="w-25" :rules="requiredRules" />
                      </div>
                      <v-text-field v-model="requestAccount.postal" :label="appConfig.texts.login.request.postal" required :rules="requiredRules" />
                      <v-text-field v-model="requestAccount.city" :label="appConfig.texts.login.request.city" required :rules="requiredRules" />
                      <v-text-field v-model="requestAccount.country" :label="appConfig.texts.login.request.country" required :rules="requiredRules" />
                    </div>
                    <div class="mt-6 px-2 py-3 bg-white">
                      <label>
                        <b> {{ appConfig.texts.login.request.headers.personal }} </b>
                      </label>
                      <v-divider class="mb-3" />
                      <v-text-field v-model="requestAccount.first_name" :label="appConfig.texts.login.request.first_name" required :rules="requiredRules" />
                      <v-text-field v-model="requestAccount.last_name" :label="appConfig.texts.login.request.last_name" required :rules="requiredRules" />
                      <v-text-field v-model="requestAccount.function" :label="appConfig.texts.login.request.function" required :rules="requiredRules" />
                    </div>
                    <div class="mt-6 px-2 py-3 bg-white">
                      <label>
                        <b> {{ appConfig.texts.login.request.headers.contact }} </b>
                      </label>
                      <v-divider class="mb-3" />
                      <v-text-field v-model="requestAccount.phone" :label="appConfig.texts.login.request.phone" required :rules="requiredRules" />
                      <v-text-field v-model="requestAccount.email" :label="appConfig.texts.login.request.email" required :rules="emailRules" />
                      <v-checkbox v-model="termsAccept" :label="appConfig.texts.login.request.terms" class="terms" />
                      <v-checkbox v-model="privacyAccept" :label="appConfig.texts.login.request.privacy" class="privacy" />
                    </div>
                  </v-form>
                </v-card-text>
                <v-card-actions>
                  <v-btn class="mx-auto px-15 my-4" color="primary" :loading="submitting" @click.prevent="doRequest()">
                    {{ appConfig.texts.login.request.buttonLabel }}
                  </v-btn>
                  <v-snackbar color="red darken-1" outlined pill v-model="errorRequest" absolute style="bottom: 5px">
                    {{ errorText }}
                    <template v-slot:action="{ attrs }">
                      <v-btn color="primary" small v-bind="attrs" @click="closeError">
                        {{ appConfig.texts.login.closeErrorButtonLabel }}
                      </v-btn>
                    </template>
                  </v-snackbar>
                </v-card-actions>
              </template>
              <template v-if="requestAccountDone">
                <v-card-text>
                  <div class="pa-3 bg-white" v-html="appConfig.texts.login.accountRequestDescriptionDone" />
                </v-card-text>
              </template>
            </v-tab-item>
            <!-- PW RESET -->
            <v-tab-item>
              <template v-if="!requestPasswordDone">
                <v-card-text>
                  <div class="" v-html="appConfig.texts.login.passwordResetDescription" />
                  <v-form class="pa-3" ref="formReset" v-model="validReset" lazy-validation>
                    <v-text-field :label="appConfig.texts.login.loginLabel" v-model="passwordReset" filled prepend-icon="mdi-email" autocomplete="emailaddress" type="email" required :rules="emailRules"></v-text-field>
                  </v-form>
                </v-card-text>
                <v-card-actions>
                  <v-btn class="mx-auto px-15 mb-4" color="primary" :loading="submitting" @click.prevent="doReset()">
                    {{ appConfig.texts.login.reset.buttonLabel }}
                  </v-btn>
                  <v-snackbar color="red darken-1" outlined pill v-model="errorReset" absolute style="bottom: 5px">
                    {{ errorText }}
                    <template v-slot:action="{ attrs }">
                      <v-btn color="primary" small v-bind="attrs" @click="closeError">
                        {{ appConfig.texts.login.closeErrorButtonLabel }}
                      </v-btn>
                    </template>
                  </v-snackbar>
                </v-card-actions>
              </template>
              <template v-if="requestPasswordDone">
                <v-card-text>
                  <div class="pa-3 bg-white" v-html="appConfig.texts.login.passwordResetDescriptionDone" />
                </v-card-text>
              </template>
            </v-tab-item>
          </v-tabs-items>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>
<script>
// ADMIN ACCOUNT
const GUID = process.env.VUE_APP_API_GUID;
const API_URL = process.env.VUE_APP_API_URL;
const INSTALL_ENV = process.env.VUE_APP_INSTALL_ENV;
export default {
  name: "Login",
  title: "",
  data() {
    return {
      passwordReset: "",
      requiredRules: [(v) => !!v || this.appConfig.texts.login.request.requiredField],
      emailRules: [
        (v) => !!v || this.appConfig.texts.login.request.requiredField,
        (v) => {
          const pattern = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
          return pattern.test(v) || this.appConfig.texts.login.request.requiredEmail;
        },
      ],
      valid: false,
      validRequest: false,
      validReset: false,
      installEnv: INSTALL_ENV,
      authTabs: 0,
      submitting: false,
      apiPublicUrl: API_URL,
      fromRoute: null,
      error: false,
      errorRequest: false,
      errorReset: false,
      errorText: "",
      productsArray: null,
      passwordType: false,
      startTime: null,
      user: {
        email: "",
        password: "",
      },
      requestPasswordDone: false,
      requestAccountDone: false,
      termsAccept: true,
      privacyAccept: true,
      requestAccount: {
        company: "",
        street: "",
        number: "",
        postal: "",
        city: "",
        country: "",
        first_name: "",
        last_name: "",
        function: "",
        email: "",
        startTime: "",
      },
    };
  },
  mounted() {
    this.getTime();
  },
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
  },
  methods: {
    getTime() {
      this.$store.dispatch("auth/getTime").then((_result) => {
        if (this.startTime == null) {
          this.startTime = _result.startTime;
        }
      });
    },
    closeError() {
      this.error = false;
      this.errorText = "";
    },
    doRequest() {
      this.$refs.formRequest.validate();
      if (this.startTime != null) {
        setTimeout(() => {
          if (this.validRequest) {
            this.submitting = true;
            this.requestAccount.startTime = this.startTime;
            this.$store.dispatch("auth/requestAccess", this.requestAccount).then(
              () => {
                this.submitting = false;
                this.requestAccount = {
                  company: "",
                  street: "",
                  number: "",
                  postal: "",
                  city: "",
                  country: "",
                  first_name: "",
                  last_name: "",
                  function: "",
                  email: "",
                  startTime: "",
                };
                this.requestAccountDone = true;
              },
              (reject) => {
                console.log(reject);
                this.submitting = false;
              },
              (error) => {
                console.log(error);
              }
            );
          } else {
            this.submitting = false;
          }
        }, 50);
      } else {
        this.submitting = false;
      }
    },
    doReset() {
      this.submitting = true;
      if (this.startTime != null) {
        this.$refs.formReset.validate();
        setTimeout(() => {
          if (this.validReset) {
            this.submitting = true;
            this.$store.dispatch("auth/reset", { email: this.passwordReset, startTime: this.startTime }).then(
              () => {
                this.submitting = false;
                this.passwordReset = "";
                this.requestPasswordDone = true;
              },
              (reject) => {
                console.log(reject);
                this.submitting = false;
              },
              (error) => {
                this.submitting = false;
                console.log(error);
              }
            );
          } else {
            this.submitting = false;
          }
        }, 50);
      } else {
        this.submitting = false;
      }
    },
    doLogin() {
      if (this.startTime != null) {
        this.submitting = true;
        this.$store.dispatch("auth/login", { user: this.user, env: this.installEnv, guid: GUID }).then(
          () => {
            this.submitting = false;
            this.$router.push("/projects");
          },
          (reject) => {
            if (reject.data.status === 400) {
              this.error = true;
              this.submitting = false;
              this.errorText = this.appConfig.texts.login.unAuthorised;
            } else if (reject.data.status === 401) {
              this.error = true;
              this.submitting = false;
              this.errorText = this.appConfig.texts.login.needToLogin;
            } else {
              this.error = true;
              this.submitting = false;
              this.errorText = this.appConfig.texts.login.unAuthorised;
            }
          },
          (error) => {
            this.submitting = false;
            this.error = true;
            this.errorText = error.error_description;
          }
        );
      }
    },
  },
};
</script>
