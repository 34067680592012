import axios from "axios";
const API_URL = process.env.VUE_APP_API_URL;
class LiApiService {
  getCollection(_data) {
    const baseURI = `${API_URL}collection/getCollection`;
    const body = _data;
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    return axios
      .post(baseURI, body, config)
      .then(function (response) {
        // console.log(response);
        return Promise.resolve(response.data);
      })
      .catch((error) => {
        console.debug(error);
      });
  }
  createCollectionItem(object) {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("liPublicSession"),
      },
    };
    return axios
      .post(API_URL + "api/collection/createCollectionItem", object, config)
      .then(function (response) {
        return Promise.resolve(response.data);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }
  updateCollectionItem(object) {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("liPublicSession"),
      },
    };
    return axios
      .post(API_URL + "api/collection/updateCollectionItem", object, config)
      .then(function (response) {
        return Promise.resolve(response.data);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }
  removeCollectionItem(object) {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("liPublicSession"),
      },
    };
    return axios
      .post(API_URL + "api/collection/removeCollectionItem", object, config)
      .then(function (response) {
        return Promise.resolve(response.data);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }
  moveCollectionItem(object) {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("liPublicSession"),
      },
    };
    return axios
      .post(API_URL + "api/collection/moveCollectionItem", object, config)
      .then(function (response) {
        return Promise.resolve(response.data);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }

  getQuestionsR() {
    const baseURI = API_URL + "/collection/getPresentation";
    const body = {
      object: "_presentation",
      where: "status [=>] 1 || identifier [=>] b6e200db-1c96-4a8d-b12a-3a4449c1bd0d",
      orderBy: "id ASC",
      select: "name, identifier",
      nodes: {
        object: "_presentation_question",
        select: "identifier, name, question, alertText, alertClass,  product,start,type, product_tns,product_tnc,product_tt",
        parentProperty: "presentation",
      },
      links: {
        object: "_presentation_link",
        select: "identifier, name, from, to, toPrefix, toSuffix, toValue,if,is",
        parentProperty: "presentation",
      },
    };
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    return axios
      .post(baseURI, body, config)
      .then(function (response) {
        return Promise.resolve(response.data.payload.questions);
      })
      .catch((error) => {
        console.log(error);
      });
  }
  getQuestionsI() {
    const baseURI = API_URL + "/collection/getPresentation";
    const body = {
      object: "_presentation",
      where: "status [=>] 1 || identifier [=>] 3d83fb34-2059-4a6a-97bd-2844f5061276",
      orderBy: "id ASC",
      select: "name, identifier",
      nodes: {
        object: "_presentation_question",
        select: "identifier, name, question, alertText, alertClass,  product, start,type, product_tns,product_tnc,product_tt",
        parentProperty: "presentation",
      },
      links: {
        object: "_presentation_link",
        select: "identifier, name, from, to, toPrefix, toSuffix, toValue,if,is",
        parentProperty: "presentation",
      },
    };
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    return axios
      .post(baseURI, body, config)
      .then(function (response) {
        return Promise.resolve(response.data.payload.questions);
      })
      .catch((error) => {
        console.log(error);
      });
  }
  getQuestionsU() {
    const baseURI = API_URL + "/collection/getPresentation";
    const body = {
      object: "_presentation",
      where: "status [=>] 1 || identifier [=>] 3d83fb34-2059-4a6a-97bd-2844f5061276",
      orderBy: "id ASC",
      select: "name, identifier",
      nodes: {
        object: "_presentation_question",
        select: "identifier, name, question, alertText, alertClass,  product, start,type, product_tns,product_tnc,product_tt",
        parentProperty: "presentation",
      },
      links: {
        object: "_presentation_link",
        select: "identifier, name, from, to, toPrefix, toSuffix, toValue,if,is",
        parentProperty: "presentation",
      },
    };
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    return axios
      .post(baseURI, body, config)
      .then(function (response) {
        return Promise.resolve(response.data.payload.questions);
      })
      .catch((error) => {
        console.log(error);
      });
  }
}
export default new LiApiService();
