<template>
  <v-container fluid fill-height class="bg-dark">
    <v-layout align-center justify-center>
      <v-flex xs12 sm8 md6>
        <v-card elevation="10">
          <template v-if="!submitted">
            <v-toolbar dark color="primary" elevation="0">
              {{ appConfig.texts.resetPassword.createPassword }}
            </v-toolbar>
            <v-card-text>
              <v-form style="min-width: 300px">
                <div class="strength-checker-wrapper">
                  <v-text-field :label="appConfig.texts.resetPassword.passwordLabel" hide-details v-model="user.password" filled :type="passwordType ? 'text' : 'password'" prepend-icon="mdi-lock" autocomplete="new-password" :append-icon="passwordType ? 'mdi-eye' : 'mdi-eye-off'" @click:append="passwordType = !passwordType" required></v-text-field>
                  <div class="strength-bar">
                    <div class="bar" :style="`width: ${strength}%; background-color: ${color};`"></div>
                  </div>
                  <p class="text-caption pl-8">
                    {{ appConfig.texts.resetPassword.hint }}
                  </p>
                  <!-- <p class="strength-message">
                  Password Strength:
                  <span v-if="strength === -1" :style="{ color: color }">Invalid</span>
                  <span v-else-if="strength === 0" :style="{ color: color }">Very Weak</span>
                  <span v-else-if="strength === 25" :style="{ color: color }">Weak</span>
                  <span v-else-if="strength === 50" :style="{ color: color }">Medium</span>
                  <span v-else-if="strength === 75" :style="{ color: color }">Strong</span>
                  <span v-else-if="strength === 100" :style="{ color: color }">Very Strong</span>
                </p> -->
                  <!-- <template v-if="showInstructions"> -->
                  <!-- <p>For a strong password:</p> -->
                  <!-- <v-list>
                    <v-list-item :style="`color: ${!testSpaces() ? 'green' : 'red'};`">Do not include spaces</v-list-item>
                    <v-list-item :style="`color: ${testLength() ? 'green' : 'red'};`">Use at least {{ length }} characters</v-list-item>
                    <v-list-item :style="`color: ${testUpper() ? 'green' : 'red'};`">Use at least one uppercase letter</v-list-item>
                    <v-list-item :style="`color: ${testNumber() ? 'green' : 'red'};`">Use at least one number</v-list-item>
                    <v-list-item :style="`color: ${testSpecial() ? 'green' : 'red'};`">Use at least one special character</v-list-item>
                  </v-list>
                </template> -->
                </div>
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-btn class="mx-auto px-15 mb-4" color="primary" :loading="submitting" @click.prevent="doValidate()">
                {{ appConfig.texts.resetPassword.buttonLabel }}
              </v-btn>
              <v-snackbar color="red darken-1" outlined pill v-model="error" absolute style="bottom: 5px">
                {{ errorText }}
                <template v-slot:action="{ attrs }">
                  <v-btn color="primary" small v-bind="attrs" @click="closeError">
                    {{ appConfig.texts.resetPassword.closeErrorButtonLabel }}
                  </v-btn>
                </template>
              </v-snackbar>
            </v-card-actions>
          </template>
          <template v-else>
            <v-toolbar dark color="primary" elevation="0"> {{ appConfig.texts.resetPassword.succesTitle }} </v-toolbar>
            <v-card-text class="text-center">
              <div v-html="appConfig.texts.resetPassword.succesText "/>
            </v-card-text>
            <v-card-actions >
              <v-btn color="primary" class="mx-auto mb-3" elevation="0" v-bind="attrs" @click="closeSuccess">
                {{ appConfig.texts.resetPassword.buttonLabelSuccess }}
              </v-btn>
            </v-card-actions>
          </template>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>
<script>
// ADMIN ACCOUNT
const API_URL = process.env.VUE_APP_API_URL;
const INSTALL_ENV = process.env.VUE_APP_INSTALL_ENV;
export default {
  name: "ResetPassword",
  title: "",
  data() {
    return {
      installEnv: INSTALL_ENV,
      submitting: false,
      submitted: false,
      apiPublicUrl: API_URL,
      fromRoute: null,
      error: false,
      errorText: "",
      productsArray: null,
      passwordType: false,
      user: {
        password: "",
      },
      colors: {
        invalid: "#000",
        very_weak: "#FFF",
        weak: "#d44137",
        good: "#e36e0e",
        strong: "#c4c934",
        very_strong: "#24ed09",
      },
      showInstructions: true,
      length: 8,
    };
  },
  computed: {
    strength() {
      let strength = 0;
      if (this.user.password === "" || this.testSpaces()) {
        return -1;
      }
      strength += this.testLength() ? 25 : 0;
      strength += this.testUpper() ? 25 : 0;
      strength += this.testNumber() ? 25 : 0;
      strength += this.testSpecial() ? 25 : 0;
      return strength;
    },
    color() {
      switch (this.strength) {
        case -1:
          return this.colors.invalid;
        case 0:
          return this.colors.very_weak;
        case 25:
          return this.colors.weak;
        case 50:
          return this.colors.good;
        case 75:
          return this.colors.strong;
        case 100:
          return this.colors.very_strong;
        default:
          return "#FFF";
      }
    },
  },
  methods: {
    testLength() {
      return this.user.password.length > this.length;
    },
    testUpper() {
      const uppercase = /[A-Z]/;
      return uppercase.test(this.user.password);
    },
    testNumber() {
      const number = /[0-9]/;
      return number.test(this.user.password);
    },
    testSpecial() {
      const special = /[`!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/;
      return special.test(this.user.password);
    },
    testSpaces() {
      const spaces = /\s/;
      return spaces.test(this.user.password);
    },
    getStrength() {
      return this.strength;
    },
    closeError() {
      this.error = false;
      this.errorText = "";
    },
    closeSuccess(){
      this.submitted = false;
      this.$router.push("/login");
    },
    doValidate() {
      if (this.strength >= 100) {
        this.submitting = true;
        if (this.$route.params.guid != undefined) {
          const obj = { guid: this.$route.params.guid, password_set: this.user.password };
          this.$store.dispatch("auth/validatePassword", obj).then(
            () => {
              this.submitted = true;
              this.submitting = false;
              this.user.password = "";
            },
            (reject) => {
              console.log(reject);
            },
            (error) => {
              this.submitting = false;
              this.error = true;
              this.errorText = error.error_description;
            }
          );
        } else {
          this.submitting = false;
          this.error = true;
          this.errorText = this.appConfig.texts.resetPassword.noGuid;
        }
      }
    },
  },
};
</script>

<style scoped>
.strength-checker-wrapper .strength-bar {
  width: 100%;
  position: relative;
  border-radius: 0;
  height: 5px;
}

.strength-checker-wrapper .strength-bar .bar {
  position: absolute;
  left: 0;
  top: 0;
  border-radius: 0;
  height: 100%;
  transition: width 0.3s;
}
</style>
