import { guest, api, local } from "../../plugins/liAxios";
export const auth = {
  namespaced: true,
  state: {
    status: {
      loggedIn: localStorage.getItem("liPublicSession") ? true : false,
    },
    user: null,
  },
  actions: {
    login({ commit }, data) {
      var obj = new URLSearchParams();
      obj.append("grant_type", "password");
      obj.append("username", data.user.email);
      obj.append("password", data.user.password);
      obj.append("guid", data.guid);
      if (data.env == "ACC") {
        obj.append("location", "ACC");
      }
      return guest.post("authm/login", obj).then(
        (response) => {
          const TOKEN = response.data.access_token;
          localStorage.setItem("liPublicSession", TOKEN);
          commit("loginSuccess", response.data.user);
          return Promise.resolve();
        },
        (reject) => {
          commit("logout");
          return Promise.reject(reject.response);
        },
        (error) => {
          commit("logout");
          return Promise.reject(error);
        }
      );
    },
    reconnect({ commit }) {
      return api.post("authm/reconnect", "").then(
        (response) => {
          commit("loginSuccess", response.data.user);
          return "200";
        },
        //eslint-disable-next-line
        (reject) => {
          commit("logout");
          return "401";
        },
        //eslint-disable-next-line
        (error) => {
          commit("logout");
          return "400";
        }
      );
    },
    validate(test, data) {
      return local.post(`requests/validateInviteToken.php`, data).then(
        //eslint-disable-next-line
        (response) => {
          return "200";
        },
        //eslint-disable-next-line
        (reject) => {
          return "401";
        },
        //eslint-disable-next-line
        (error) => {
          return "400";
        }
      );
    },
    validatePassword(test, data) {
      return local.post(`requests/validatePassword.php`, data).then(
        //eslint-disable-next-line
        (response) => {
          return "200";
        },
        //eslint-disable-next-line
        (reject) => {
          return "401";
        },
        //eslint-disable-next-line
        (error) => {
          return "400";
        }
      );
    },
    requestAccess(test, data) {
      return local.post("/requests/submit.php", data).then(
        //eslint-disable-next-line
        (response) => {
          return response.data;
        },
        //eslint-disable-next-line
        (reject) => {
          return "401";
        },
        //eslint-disable-next-line
        (error) => {
          return "400";
        }
      );
    },
    reset(test,data) {
      return local.post("/requests/resetPassword.php",data).then(
        //eslint-disable-next-line
        (response) => {
          return response.data;
        },
        //eslint-disable-next-line
        (reject) => {
          return "401";
        },
        //eslint-disable-next-line
        (error) => {
          return "400";
        }
      );
    },
    getTime() {
      return local.post("/requests/getTime.php").then(
        //eslint-disable-next-line
        (response) => {
          return response.data;
        },
        //eslint-disable-next-line
        (reject) => {
          return "401";
        },
        //eslint-disable-next-line
        (error) => {
          return "400";
        }
      );
    },
    logout({ commit }) {
      commit("logout");
      return Promise.resolve();
    },
  },
  mutations: {
    loginSuccess(state, user) {
      state.status.loggedIn = true;
      state.user = user;
    },
    logout(state) {
      state.status.loggedIn = false;
      state.user = null;
      localStorage.removeItem("liPublicSession");
    },
  },
  getters: {
    getUser: (state) => {
      return state.user;
    },
    isLoggedIn: (state) => {
      return state.status.loggedIn;
    },
    loggedInTimer: (state) => {
      return state.status.timeCounter;
    },
    loggedInStatus: (state) => {
      return state.status.needsLogin;
    },
  },
};
